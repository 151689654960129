<template>
  <div>
    <div ref="print" class="print">
      <div class="page1 page">
        <header class="header">
          <div class="content">
            <div class="header__row">
              <div class="header__logo">
                <img :src="require('@/assets/img/logo.svg')" />
              </div>
              <div class="header__contacts">
                <div class="header__contact">
                  <span>8 (846) 275-25-55</span>
                </div>
                <div class="header__contact">
                  <span>г. Самара, Волжский проспект, д. 37</span>
                </div>
                <div class="header__contact">
                  <span>an5zvezd.ru</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main class="main-content">
          <section class="images">
            <div class="content">
              <div class="images__row">
                <div
                  class="images__img-container"
                  v-for="(img, ind) in images.slice(0, imagesRows*3)"
                  :key="ind"
                >
                  <img
                    class="images__img"
                    :src="
                      Item.config.imagesHost + '/' + currentLead.id + '/' + img
                    "
                  />
                </div>
              </div>
            </div>
          </section>
          <section class="main-info">
            <div class="content">
              <h1 class="main-info__title">
                {{ getCustomProp("presentationNameFieldId") }}
              </h1>
              <h2 class="main-title">Общая информация</h2>
              <div class="main-info__address">
                <span>Адрес:</span>
                {{ getCustomProp("address") }}
              </div>
              <div class="main-info__props">
                <div class="main-info__prop">
                  <div class="main-info__prop-name">Общая площадь</div>
                  <div class="main-info__prop-value">
                    {{ getCustomPropById(626451) }} м²
                  </div>
                </div>
                <div
                  class="main-info__prop"
                  v-if="getCustomPropById(643299) && getCustomPropById(643301)"
                >
                  <div class="main-info__prop-name">Этаж/Этажность</div>
                  <div class="main-info__prop-value">
                    {{ getCustomPropById(643299) }}/{{
                      getCustomPropById(643301)
                    }}
                  </div>
                </div>
                <div class="main-info__prop">
                  <div class="main-info__prop-name">
                    Стоимость/Стоимость за м²
                  </div>
                  <div class="main-info__prop-value">
                    {{
                      new Intl.NumberFormat("ru", {
                        style: "decimal",
                        maximumFractionDigits: 0,
                      }).format(getCustomPropById(637975))
                    }}
                    ₽/{{
                      new Intl.NumberFormat("ru", {
                        style: "decimal",
                        maximumFractionDigits: 0,
                      }).format(
                        getCustomPropById(637975) / getCustomPropById(626451)
                      )
                    }}
                    ₽
                  </div>
                </div>
              </div>
              <h2 class="main-title">Описание объекта</h2>
              <div
                class="main-info__description"
                v-html="getCustomPropById(643309)"
              ></div>
            </div>
          </section>

          <footer class="agent-section">
            <div class="content">
              <div class="agent-section__agent">
                <div class="agent__info-container">
                  <div class="agent__name-container" v-if="Item.agent">
                    <div class="agent__name">
                      {{ Item.agent.name }}
                    </div>
                    <div class="agent__position">
                      Специалист по продаже и аренде недвижимости
                    </div>
                  </div>
                  <div class="agent__contacts-container" v-if="Item.agent">
                    <a
                      :href="'mailto:' + Item.agent.login"
                      class="agent__email"
                    >
                      <span>{{ Item.agent.login }}</span>
                    </a>
                    <a
                      :href="'tel:' + Item.agent.phone_number"
                      class="agent__phone"
                    >
                      <span>
                        {{ Item.agent.phone_number }}
                      </span>
                    </a>
                  </div>
                  <div class="agent__requisites">
                    <div class="agent__requisite">
                      ООО АН « Пять звезд»
                      <div></div>
                      ОГРН 1106316013638,
                      <div></div>
                      ИНН 6316158605, КПП 631501001
                      <div></div>
                      ю/а 443099, Самарская обл.,
                      <div></div>
                      г. Самара, Волжский проспект,
                      <div></div>
                      дом 37, офис 1
                    </div>
                    <div class="agent__requisite">
                      р/c №40702810554400015858
                      <div></div>
                      в Самарском отделении №6991
                      <div></div>
                      ПАО «Сбербанк России»,
                      <div></div>
                      БИК 043601607,
                      <div></div>
                      к/с 30101810200000000607
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
      <!-- <div class="html2pdf__page-break"></div> -->
      <div class="page2 page" v-if="images.length > imagesRows*3 && imagesRows < 2">
        <header class="header">
          <div class="content">
            <div class="header__row">
              <div class="header__logo">
                <img :src="require('@/assets/img/logo.svg')" />
              </div>
              <div class="header__contacts">
                <div class="header__contact">
                  <span>8 (846) 275-25-55</span>
                </div>
                <div class="header__contact">
                  <span>г. Самара, Волжский проспект, д. 37</span>
                </div>
                <div class="header__contact">
                  <span>an5zvezd.ru</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <section class="images">
            <div class="content">
              <div class="images__row">
                <div
                  class="images__img-container"
                  v-for="(img, ind) in images.slice(imagesRows*3, (imagesRows*3 + 18))"
                  :key="ind"
                >
                  <img
                    class="images__img"
                    :src="
                      Item.config.imagesHost + '/' + currentLead.id + '/' + img
                    "
                  />
                </div>
              </div>
            </div>
          </section>
          <footer class="agent-section">
            <div class="content">
              <div class="agent-section__agent">
                <div class="agent__info-container">
                  <div class="agent__name-container" v-if="Item.agent">
                    <div class="agent__name">
                      {{ Item.agent.name }}
                    </div>
                    <div class="agent__position">
                      Специалист по продаже и аренде недвижимости
                    </div>
                  </div>
                  <div class="agent__contacts-container" v-if="Item.agent">
                    <a
                      :href="'mailto:' + Item.agent.login"
                      class="agent__email"
                    >
                      <span>{{ Item.agent.login }}</span>
                    </a>
                    <a
                      :href="'tel:' + Item.agent.phone_number"
                      class="agent__phone"
                    >
                      <span>
                        {{ Item.agent.phone_number }}
                      </span>
                    </a>
                  </div>
                  <div class="agent__requisites">
                    <div class="agent__requisite">
                      ООО АН « Пять звезд»
                      <div></div>
                      ОГРН 1106316013638,
                      <div></div>
                      ИНН 6316158605, КПП 631501001
                      <div></div>
                      ю/а 443099, Самарская обл.,
                      <div></div>
                      г. Самара, Волжский проспект,
                      <div></div>
                      дом 37, офис 1
                    </div>
                    <div class="agent__requisite">
                      р/c №40702810554400015858
                      <div></div>
                      в Самарском отделении №6991
                      <div></div>
                      ПАО «Сбербанк России»,
                      <div></div>
                      БИК 043601607,
                      <div></div>
                      к/с 30101810200000000607
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
      <!-- <div class="html2pdf__page-break" v-if="images.length > 18"></div> -->
      <!-- <div class="page3 page" v-if="images.length > 18">
        <header class="header">
          <div class="content">
            <div class="header__row">
              <div class="header__logo">
                <img :src="require('@/assets/img/logo.svg')" />
              </div>
              <div class="header__contacts">
                <div class="header__contact">
                  <span>8 (846) 275-25-55</span>
                </div>
                <div class="header__contact">
                  <span>г. Самара, Волжский проспект, д. 37</span>
                </div>
                <div class="header__contact">
                  <span>an5zvezd.ru</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <section class="images">
            <div class="content">
              <div class="images__row">
                <div
                  class="images__img-container"
                  v-for="(img, ind) in images.slice(18, 37)"
                  :key="ind"
                >
                  <img
                    class="images__img"
                    :src="
                      Item.config.imagesHost + '/' + currentLead.id + '/' + img
                    "
                  />
                </div>
              </div>
            </div>
          </section>
          <footer class="agent-section">
            <div class="content">
              <div class="agent-section__agent">
                <div class="agent__info-container">
                  <div class="agent__name-container" v-if="Item.agent">
                    <div class="agent__name">
                      {{ Item.agent.name }}
                    </div>
                    <div class="agent__position">
                      Специалист по продаже и аренде недвижимости
                    </div>
                  </div>
                  <div class="agent__contacts-container" v-if="Item.agent">
                    <a
                      :href="'mailto:' + Item.agent.login"
                      class="agent__email"
                    >
                      <span>{{ Item.agent.login }}</span>
                    </a>
                    <a
                      :href="'tel:' + Item.agent.phone_number"
                      class="agent__phone"
                    >
                      <span>
                        {{ Item.agent.phone_number }}
                      </span>
                    </a>
                  </div>
                  <div class="agent__requisites">
                    <div class="agent__requisite">
                      ООО АН « Пять звезд»
                      <div></div>
                      ОГРН 1106316013638,
                      <div></div>
                      ИНН 6316158605, КПП 631501001
                      <div></div>
                      ю/а 443099, Самарская обл.,
                      <div></div>
                      г. Самара, Волжский проспект,
                      <div></div>
                      дом 37, офис 1
                    </div>
                    <div class="agent__requisite">
                      р/c №40702810554400015858
                      <div></div>
                      в Самарском отделении №6991
                      <div></div>
                      ПАО «Сбербанк России»,
                      <div></div>
                      БИК 043601607,
                      <div></div>
                      к/с 30101810200000000607
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PrintTemplate",
  data: () => ({
    imagesRows: 0,
  }),
  computed: {
    ...mapGetters(["Item"]),
    currentLead() {
      return this.Item.leads[0];
    },
    images() {
      if (!this.Item.config.images) return [];
      let imgFields = this.Item.config.images.map((el) => el.id);
      let imgArr = [];
      for (let imgProp of imgFields) {
        imgArr = imgArr.concat(
          Object.keys(JSON.parse(this.getCustomPropById(imgProp)))
        );
      }
      return imgArr;
    },
    mapMarker() {
      return {
        markerId: 1,
        hideIconOnBalloonOpen: false,
        coords: [
          this.getCustomProp("latitude"),
          this.getCustomProp("longitude"),
        ],
        options: {
          hideIconOnBalloonOpen: false,
          syncOverlayInit: true,
          balloonOffset: [0, -30],
        },
      };
    },
  },
  methods: {
    ...mapActions(["fetchItem"]),
    getCustomProp(propName) {
      let prop = this.currentLead.custom_fields_values.find(
        (field) => field.field_id === this.Item.config[propName]
      );
      if (prop) {
        return prop.values[0].value;
      }
      return false;
    },
    getCustomPropById(id) {
      let prop = this.currentLead.custom_fields_values.find(
        (field) => field.field_id === id
      );
      if (prop) {
        return prop.values[0].value;
      }
      return false;
    },
  },
  async created() {
    // await this.fetchItem({
    //   domain: this.$route.params.domain,
    //   personalId: this.$route.params.id,
    //   leadId: this.$route.query.ids,
    // });
  },
  mounted() {
    let print = this.$refs.print;
    if (print) {
      let printRect = print.getBoundingClientRect();
      // print.style.minHeight = Math.ceil(printRect.height / 2035) * 2035 + "px";
      let content = print.querySelector(".main-content")
      let contentHeight = content.getBoundingClientRect().height
      let footer = content.querySelector(".agent-section")
      let footerHeight = footer.getBoundingClientRect().height
      let header = print.querySelector(".header")
      let headerHeight = header.getBoundingClientRect().height
      let description = content.querySelector(".main-info")
      let descriptionHeigth = description.getBoundingClientRect().height
      this.imagesRows = Math.floor((2035 - headerHeight - (footerHeight + 40) - descriptionHeigth)/240)
    }
  },
};
</script>

<style lang="scss" scoped>
.print {
  // min-height: 2035px;
   border-right: 50px solid #ee3686;
  // display: flex;
  // flex-direction: column;
  // &::before {
  //   width: 50px;
  //   height: 3850px;
  //   background-color: #ee3686;
  //   position: fixed;
  //   top: 0;
  //   left: 1650px;
  //   display: block;
  //   content: "";
  // }

  & .content {
  width: 100%;
  max-width: 1280px;
  padding: 0 76px;
  margin: 0 auto;
 

  @media screen and (max-width: 1220px) {
    width: 100%;
    padding: 0 76px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 76px;
  }
}

  & main {
    flex-grow: 1;
    height: 100%;
  }

  .page {
    height: 2035px;
    width: 1440px;
    // border-right: 50px solid #ee3686;
    display: flex;
    flex-direction: column;
  }
}
.header {
  padding: 20px 0;
  background-color: #fff;
   border-right: 50px solid #ee3686;

  &__logo {
    flex-shrink: 0;

    & img {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__contacts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__contact {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 0;
    color: #000000;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }

    & span {
      white-space: nowrap;
    }
  }
}
main {
  display: flex;
  flex-direction: column;
}
.main {
  &-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    text-transform: uppercase;
  }

  &-info {
    padding: 20px 0 40px;

    &__title {
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 36px;
      font-weight: 500;
      line-height: 42px;
      text-align: left;
    }

    &__address {
      & span {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
      }
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
    }

    &__props {
      margin-bottom: 40px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // column-gap: 70px;
    }

    &__prop {
      margin: 0 35px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
      &-name {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
      }

      &-value {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
      }
    }

    &__description {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;

      text-align: justify;
    }
  }
}

.images {
  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  &__img-container {
    width: 100%;
    height: 240px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.agent-section {
  padding: 40px 0;
  margin-top: auto;
  margin-bottom: 40px;

  &__agent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.agent {
  &__info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  &__position {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  &__contacts-container {
    margin-left: 40px;
    margin-right: 40px;
  }

  &__email {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    line-height: 24px;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

  &__phone {
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }

    & span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__requisites {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  &__requisite {
    margin: 0 35px;
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

<style lang="scss">
.main-info__description {
  p {
    margin-bottom: 0;
  }

  ul {
    margin: 10px 0;
    list-style: initial;
    padding-inline-start: 40px;
  }

  li {
    margin-bottom: 8px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  b,
  strong {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }
}
</style>