<template>
  <div>
    <div v-if="Item && Object.keys(Item).length">
      <Header />
      <main>
        <section class="slider" v-if="images && images.length">
          <div class="content">
            <h1 class="slider__title">
              {{ getCustomProp("presentationNameFieldId") }}
            </h1>
            <div class="slider__container">
              <div class="slider__arrows">
                <div
                  class="slider__arrow slider__arrow_left"
                  @click="$refs.mainSlider.prev()"
                >
                  <svg
                    width="23"
                    height="12"
                    viewBox="0 0 23 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.3125 1L1.0625 6M1.0625 6L7.3125 10.6875M1.0625 6H22.625"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  class="slider__arrow slider__arrow_right"
                  @click="$refs.mainSlider.next()"
                >
                  <svg
                    width="23"
                    height="12"
                    viewBox="0 0 23 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6875 1L21.9375 6M21.9375 6L15.6875 10.6875M21.9375 6H0.375"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <VueSlickCarousel
                class="slider__main"
                ref="mainSlider"
                v-bind="mainSettings"
                :asNavFor="$refs.thumbSlider"
                @beforeChange="beforeChange"
              >
                {{ images }}
                <img
                  class="slider__img"
                  @click="
                    openFancybox(
                      Item.config.imagesHost + '/' + currentLead.id + '/' + img
                    )
                  "
                  :src="
                    Item.config.imagesHost + '/' + currentLead.id + '/' + img
                  "
                  v-for="(img, ind) in images"
                  :key="ind"
                />
              </VueSlickCarousel>
            </div>
            <div class="slider__thumb-container">
              <VueSlickCarousel
                class="slider__thumb"
                ref="thumbSlider"
                v-bind="thumbSettings"
                :asNavFor="$refs.mainSlider"
              >
                {{ images }}
                <div
                  class="slider__thumb-img-container"
                  v-for="(img, ind) in images"
                  :key="ind"
                  @click="$refs.mainSlider.goTo(ind)"
                >
                  <img
                    class="slider__thumb-img"
                    :src="
                      Item.config.imagesHost + '/' + currentLead.id + '/' + img
                    "
                  />
                </div>
              </VueSlickCarousel>
            </div>
          </div>
        </section>
        <section class="main-info">
          <div class="content">
            <h2 class="main-title">Общая информация</h2>
            <div class="main-info__address">
              <span>Адрес:</span>
              {{ getCustomProp("address") }}
            </div>
            <div class="main-info__props">
              <div class="main-info__prop">
                <div class="main-info__prop-name">Общая площадь</div>
                <div class="main-info__prop-value">
                  {{ getCustomPropById(626451) }} м²
                </div>
              </div>
              <div
                class="main-info__prop"
                v-if="getCustomPropById(643299) && getCustomPropById(643301)"
              >
                <div class="main-info__prop-name">Этаж/Этажность</div>
                <div class="main-info__prop-value">
                  {{ getCustomPropById(643299) }}/{{
                    getCustomPropById(643301)
                  }}
                </div>
              </div>
              <div class="main-info__prop mobile-hide">
                <div class="main-info__prop-name">
                  Стоимость/Стоимость за м²
                </div>
                <div class="main-info__prop-value">
                  {{
                    new Intl.NumberFormat("ru", {
                      style: "decimal",
                      maximumFractionDigits: 0,
                    }).format(getCustomPropById(637975))
                  }}
                  ₽/{{
                    new Intl.NumberFormat("ru", {
                      style: "decimal",
                      maximumFractionDigits: 0,
                    }).format(
                      getCustomPropById(637975) / getCustomPropById(626451)
                    )
                  }}
                  ₽
                </div>
              </div>
              <div class="main-info__prop desktop-hide">
                <div class="main-info__prop-name">Стоимость</div>
                <div class="main-info__prop-value">
                  {{
                    new Intl.NumberFormat("ru", { style: "decimal" }).format(
                      getCustomPropById(637975)
                    )
                  }}
                  ₽
                </div>
              </div>
              <div class="main-info__prop desktop-hide">
                <div class="main-info__prop-name">Стоимость за м²</div>
                <div class="main-info__prop-value">
                  {{
                    new Intl.NumberFormat("ru", { style: "decimal" }).format(
                      getCustomPropById(637975) / getCustomPropById(626451)
                    )
                  }}
                  ₽
                </div>
              </div>
            </div>
            <h2 class="main-title">Описание объекта</h2>
            <div
              class="main-info__description"
              v-html="getCustomPropById(643309)"
            ></div>
          </div>
        </section>
        <section class="map-section">
          <div class="content">
            <div class="map-section__row">
              <div class="map-section__map-container">
                <h2 class="main-title">Объект на карте</h2>
                <yandex-map
                  :key="'ymaps_1'"
                  v-bind="mapSettings"
                  ymap-class="map-section__map"
                  :coords="[
                    getCustomProp('latitude'),
                    getCustomProp('longitude'),
                  ]"
                  :settings="mapMainSettings"
                  :scroll-zoom="true"
                >
                  <!--Markers-->
                  <ymapMarker :icon="icon" v-bind="mapMarker"></ymapMarker>
                </yandex-map>
              </div>
              <div class="map-section__video" v-if="getCustomProp('videoId')">
                <h2 class="main-title">Видео об объекте</h2>
                <iframe
                  class="map-section__video-frame"
                  :src="`https://www.youtube.com/embed/${getYoutubeId()}`"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section class="agent-section" v-if="Item.agent">
          <div class="content">
            <div class="agent-section__agent">
              <div class="agent__image-container">
                <img
                  :src="Item.agent.photo_url"
                  class="agent__image"
                />
                <div class="agent__phone-icons">
                  <a
                    :href="`whatsapp://send?&phone=${Item.agent.phone_number}&abid=${Item.agent.phone_number}`"
                    target="_blank"
                  >
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="30" height="30" rx="10" fill="#29A71A" />
                      <path
                        d="M4.81689 25.1816C5.27217 23.8334 5.69479 22.5787 6.11923 21.3249C6.25436 20.9248 6.38133 20.5211 6.5346 20.1273C6.61803 19.9123 6.53641 19.7599 6.44028 19.5802C4.89942 16.6952 4.69718 13.7231 6.02944 10.7255C8.91979 4.21966 17.5282 2.73177 22.4492 7.85953C27.3628 12.9791 25.4601 21.4755 18.826 24.0031C16.1189 25.0346 13.4426 24.9103 10.8243 23.6774C10.4897 23.5195 10.2067 23.4741 9.84393 23.5957C8.41282 24.0756 6.96992 24.5211 5.53155 24.9765C5.33112 25.04 5.12797 25.0927 4.8178 25.1816H4.81689ZM7.34719 22.644C7.46781 22.7293 7.53855 22.6857 7.60657 22.664C8.44003 22.3991 9.27711 22.1414 10.1051 21.8611C10.3972 21.7622 10.6375 21.7558 10.9096 21.9382C11.3358 22.224 11.8138 22.4145 12.2953 22.5968C17.3006 24.4875 22.9208 21.0935 23.571 15.7816C24.1097 11.3778 21.2312 7.3678 16.8081 6.53586C13.3283 5.88083 10.4117 7.01579 8.34752 9.88632C6.22624 12.8358 6.21173 15.9885 8.02557 19.1194C8.28041 19.5585 8.29583 19.8878 8.1344 20.3233C7.84781 21.0935 7.60476 21.8792 7.3481 22.644H7.34719Z"
                        fill="white"
                      />
                      <path
                        d="M12.17 10.1748C12.4874 10.1185 12.6842 10.27 12.8039 10.5549C13.0724 11.1945 13.3517 11.8305 13.6102 12.4737C13.7535 12.8303 13.5059 13.0779 13.31 13.3202C12.7386 14.0278 12.7341 14.0242 13.2656 14.7591C14.0165 15.7997 14.9697 16.5817 16.1659 17.0653C16.4797 17.1923 16.7073 17.176 16.9095 16.8866C17.0474 16.6897 17.2496 16.5327 17.3657 16.3259C17.6233 15.8686 17.9462 15.8378 18.3788 16.0673C18.8712 16.3286 19.3755 16.5699 19.8879 16.7904C20.2488 16.9455 20.325 17.1941 20.2697 17.5516C20.0783 18.7854 18.7216 19.6555 17.4483 19.3715C14.9443 18.8127 12.9817 17.4672 11.5261 15.3606C11.001 14.6003 10.4641 13.8482 10.3435 12.8983C10.2464 12.1326 10.4124 11.4349 10.8867 10.8089C11.2141 10.3771 11.6295 10.1602 12.17 10.1729V10.1748Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    :href="`https://t.me/${Item.agent.phone_number}`"
                    target="_blank"
                  >
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="30" height="30" rx="10" fill="#06A4F0" />
                      <path
                        d="M18.9384 23.1911L13.1145 19.3695C12.5593 18.8446 12.3789 18.1638 12.7162 17.7678L18.7101 11.8877C18.8243 11.6594 18.7101 11.431 18.4247 11.5452L10.8895 16.626C9.98512 17.1777 9.45682 17.3177 8.49198 17.254L5.29524 16.3406C4.03937 16.0551 3.98231 15.1417 4.89565 14.5709C11.336 11.7585 14.9372 10.2837 21.3453 7.83448C21.3453 7.83448 22.4777 7.32069 23.1056 7.66321C23.7335 8.00574 23.5052 8.86206 23.5052 8.86206C22.7016 14.2331 22.3108 17.0209 21.5072 22.3919C21.1076 23.6478 19.7947 23.8191 18.9384 23.1911Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div class="agent__info-container">
                <div class="agent__name-container">
                  <div class="agent__name">
                    {{ Item.agent.name }}
                  </div>
                  <div class="agent__position">
                    Специалист по продаже и аренде недвижимости
                  </div>
                </div>
                <div class="agent__contacts-container">
                  <a :href="'mailto:' + Item.agent.login" class="agent__email">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8" clip-path="url(#clip0_2048_109)">
                        <path
                          d="M4 8.47767L4.16776 7.99387C6.17163 10.0288 8.15788 12.0458 10.1021 14.0195C8.18723 15.9634 6.19902 17.9813 4.17901 20.0314C4.1076 19.826 4.0538 19.6722 4 19.5184C4 15.8383 4 12.1582 4 8.47767Z"
                          fill="black"
                        />
                        <path
                          d="M4.9729 7.12751C5.27761 7.0803 5.53635 7.00729 5.79558 7.00632C8.02149 6.99707 10.2474 7.00097 12.4733 7.00097C15.7083 7.00097 18.9427 6.99853 22.1777 7.00583C22.4437 7.00632 22.7098 7.07689 22.9754 7.11486L22.9813 7.18203C22.9392 7.22729 22.8991 7.2745 22.8556 7.31782C20.3504 9.79912 17.8442 12.2794 15.3405 14.7617C14.8015 15.2961 14.1705 15.5156 13.4403 15.2616C13.1683 15.1671 12.9018 14.9895 12.6954 14.7865C10.1633 12.2965 7.64244 9.79376 5.11914 7.29446C5.08344 7.25893 5.05262 7.21853 4.9729 7.12751Z"
                          fill="black"
                        />
                        <path
                          d="M11.0597 14.8026C11.3453 15.0888 11.607 15.3482 11.8662 15.6101C12.277 16.0253 12.7588 16.3217 13.334 16.4458C14.4105 16.6775 15.3374 16.3869 16.1185 15.6222C16.3826 15.3633 16.6389 15.0971 16.9226 14.8099C18.9489 16.8147 20.9626 18.8069 23.0222 20.8447C22.7718 20.9012 22.5644 20.9547 22.3546 20.9913C22.2533 21.0088 22.1467 20.9956 22.0425 20.9956C16.6403 20.9956 11.2387 20.9976 5.8365 20.9908C5.56407 20.9908 5.29164 20.9197 5.06519 20.8881C7.0666 18.856 9.05482 16.8376 11.0592 14.8026H11.0597Z"
                          fill="black"
                        />
                        <path
                          d="M23.7775 20.0013C21.7928 17.9858 19.808 15.9707 17.8638 13.9971C19.806 12.0249 21.7908 10.0099 23.7981 7.97198C23.9526 8.25234 23.9986 8.50397 23.9981 8.76923C23.9971 11.179 23.9976 13.5892 23.9976 15.999C23.9976 17.0809 24.005 18.1629 23.9918 19.2444C23.9888 19.4902 23.915 19.7345 23.8734 19.9798L23.7771 20.0008L23.7775 20.0013Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2048_109">
                          <rect
                            width="20"
                            height="14"
                            fill="white"
                            transform="translate(4 7)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>{{ Item.agent.login }}</span>
                  </a>
                  <a
                    :href="'tel:' + Item.agent.phone_number"
                    class="agent__phone"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.8"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.36457 6.06611C8.12787 6.1445 8.04094 6.21167 7.4844 6.74634C6.74569 7.456 6.56611 7.66402 6.37963 8.02616C3.66261 13.3029 16.2746 25.1025 20.5462 21.2803C21.8146 20.1453 22.157 19.586 21.9387 19.0059C21.8763 18.8402 19.2414 16.1452 18.9723 15.9719C18.46 15.6421 18.0754 15.7637 17.3406 16.4879C16.292 17.5214 15.8088 17.5263 14.3055 16.5186C13.1239 15.7265 12.0804 14.6596 11.36 13.5071C10.5108 12.1486 10.5416 11.7049 11.556 10.6789C12.6142 9.60877 12.5994 9.56078 10.5953 7.56442C8.98011 5.95544 8.88725 5.89308 8.36457 6.06611Z"
                        fill="black"
                      />
                    </svg>

                    <span>
                      {{ Item.agent.phone_number }}
                      <div class="agent__phone-icons">
                        <a
                          :href="`whatsapp://send?&phone=${Item.agent.phone_number}&abid=${Item.agent.phone_number}`"
                          target="_blank"
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="30"
                              height="30"
                              rx="10"
                              fill="#29A71A"
                            />
                            <path
                              d="M4.81689 25.1816C5.27217 23.8334 5.69479 22.5787 6.11923 21.3249C6.25436 20.9248 6.38133 20.5211 6.5346 20.1273C6.61803 19.9123 6.53641 19.7599 6.44028 19.5802C4.89942 16.6952 4.69718 13.7231 6.02944 10.7255C8.91979 4.21966 17.5282 2.73177 22.4492 7.85953C27.3628 12.9791 25.4601 21.4755 18.826 24.0031C16.1189 25.0346 13.4426 24.9103 10.8243 23.6774C10.4897 23.5195 10.2067 23.4741 9.84393 23.5957C8.41282 24.0756 6.96992 24.5211 5.53155 24.9765C5.33112 25.04 5.12797 25.0927 4.8178 25.1816H4.81689ZM7.34719 22.644C7.46781 22.7293 7.53855 22.6857 7.60657 22.664C8.44003 22.3991 9.27711 22.1414 10.1051 21.8611C10.3972 21.7622 10.6375 21.7558 10.9096 21.9382C11.3358 22.224 11.8138 22.4145 12.2953 22.5968C17.3006 24.4875 22.9208 21.0935 23.571 15.7816C24.1097 11.3778 21.2312 7.3678 16.8081 6.53586C13.3283 5.88083 10.4117 7.01579 8.34752 9.88632C6.22624 12.8358 6.21173 15.9885 8.02557 19.1194C8.28041 19.5585 8.29583 19.8878 8.1344 20.3233C7.84781 21.0935 7.60476 21.8792 7.3481 22.644H7.34719Z"
                              fill="white"
                            />
                            <path
                              d="M12.17 10.1748C12.4874 10.1185 12.6842 10.27 12.8039 10.5549C13.0724 11.1945 13.3517 11.8305 13.6102 12.4737C13.7535 12.8303 13.5059 13.0779 13.31 13.3202C12.7386 14.0278 12.7341 14.0242 13.2656 14.7591C14.0165 15.7997 14.9697 16.5817 16.1659 17.0653C16.4797 17.1923 16.7073 17.176 16.9095 16.8866C17.0474 16.6897 17.2496 16.5327 17.3657 16.3259C17.6233 15.8686 17.9462 15.8378 18.3788 16.0673C18.8712 16.3286 19.3755 16.5699 19.8879 16.7904C20.2488 16.9455 20.325 17.1941 20.2697 17.5516C20.0783 18.7854 18.7216 19.6555 17.4483 19.3715C14.9443 18.8127 12.9817 17.4672 11.5261 15.3606C11.001 14.6003 10.4641 13.8482 10.3435 12.8983C10.2464 12.1326 10.4124 11.4349 10.8867 10.8089C11.2141 10.3771 11.6295 10.1602 12.17 10.1729V10.1748Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                        <a
                          :href="`https://t.me/${Item.agent.phone_number}`"
                          target="_blank"
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="30"
                              height="30"
                              rx="10"
                              fill="#06A4F0"
                            />
                            <path
                              d="M18.9384 23.1911L13.1145 19.3695C12.5593 18.8446 12.3789 18.1638 12.7162 17.7678L18.7101 11.8877C18.8243 11.6594 18.7101 11.431 18.4247 11.5452L10.8895 16.626C9.98512 17.1777 9.45682 17.3177 8.49198 17.254L5.29524 16.3406C4.03937 16.0551 3.98231 15.1417 4.89565 14.5709C11.336 11.7585 14.9372 10.2837 21.3453 7.83448C21.3453 7.83448 22.4777 7.32069 23.1056 7.66321C23.7335 8.00574 23.5052 8.86206 23.5052 8.86206C22.7016 14.2331 22.3108 17.0209 21.5072 22.3919C21.1076 23.6478 19.7947 23.8191 18.9384 23.1911Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </div>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="bottom-banner">
          <div class="content">
            <div class="bottom-banner__row">
              <div class="bottom-banner__title">
                Работаем для вас <span>на 5 звезд!</span>
              </div>
              <a
                class="button bottom-banner__button"
                href="https://an5zvezd.ru/"
                target="_blank"
                >Перейти на сайт</a
              >
            </div>
          </div>
        </section>
        <section class="dropdowns">
          <div class="content">
            <div class="dropdown">
              <input type="checkbox" id="dropdown_1" />
              <label for="dropdown_1" class="dropdown__head">
                <div class="dropdown__title">Услуги компании</div>
                <div class="dropdown__arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.8">
                      <rect
                        x="10.9092"
                        width="2.18182"
                        height="24"
                        fill="black"
                      />
                      <rect
                        y="13.091"
                        width="2.18182"
                        height="24"
                        transform="rotate(-90 0 13.091)"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </label>
              <div class="dropdown__body">
                <div class="dropdown__row">
                  <div class="dropdown__items">
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">01</div>
                      <div class="dropdown__item-text">
                        Продажа, покупка и аренда коммерческой недвижимости
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">02</div>
                      <div class="dropdown__item-text">
                        Продажа и покупка жилой и&nbsp;загородной недвижимости
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">03</div>
                      <div class="dropdown__item-text">
                        Эффективные инвестиции в недвижимость, продажа
                        и&nbsp;покупка готового арендного бизнеса
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">04</div>
                      <div class="dropdown__item-text">
                        Срочный выкуп недвижимости
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">05</div>
                      <div class="dropdown__item-text">
                        Сопровождение покупки недвижимости в&nbsp;ОАЭ
                      </div>
                    </div>
                  </div>
                  <div class="dropdown__image-container">
                    <img
                      class="dropdown__image"
                      :src="require('@/assets/img/dropdown1.jpg')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <input type="checkbox" id="dropdown_2" />
              <label for="dropdown_2" class="dropdown__head">
                <div class="dropdown__title">Управление арендным бизнесом</div>
                <div class="dropdown__arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.8">
                      <rect
                        x="10.9092"
                        width="2.18182"
                        height="24"
                        fill="black"
                      />
                      <rect
                        y="13.091"
                        width="2.18182"
                        height="24"
                        transform="rotate(-90 0 13.091)"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </label>
              <div class="dropdown__body">
                <div class="dropdown__row dropdown__row_reverse">
                  <div class="dropdown__image-container">
                    <img
                      class="dropdown__image"
                      :src="require('@/assets/img/dropdown2.jpg')"
                    />
                  </div>
                  <div class="dropdown__items">
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">01</div>
                      <div class="dropdown__item-text">Поиск арендаторов</div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">02</div>
                      <div class="dropdown__item-text">
                        Юридическое сопровождение договоров аренды
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">03</div>
                      <div class="dropdown__item-text">
                        Бухгалтерское сопровождение
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">04</div>
                      <div class="dropdown__item-text">
                        Техническое обслуживание объектов и оборудования
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">05</div>
                      <div class="dropdown__item-text">Текущий ремонт</div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">06</div>
                      <div class="dropdown__item-text">
                        Организацию клининга и охраны
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <input type="checkbox" id="dropdown_3" />
              <label for="dropdown_3" class="dropdown__head">
                <div class="dropdown__title">
                  Юридические услуги в&nbsp;сфере недвижимости
                </div>
                <div class="dropdown__arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.8">
                      <rect
                        x="10.9092"
                        width="2.18182"
                        height="24"
                        fill="black"
                      />
                      <rect
                        y="13.091"
                        width="2.18182"
                        height="24"
                        transform="rotate(-90 0 13.091)"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </label>
              <div class="dropdown__body">
                <div class="dropdown__row">
                  <div class="dropdown__items">
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">01</div>
                      <div class="dropdown__item-text">
                        Сопровождение сделок с&nbsp;недвижимостью любой
                        сложности
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">02</div>
                      <div class="dropdown__item-text">
                        Юридическая проверка объекта недвижимости
                        и&nbsp;участников сделки
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">03</div>
                      <div class="dropdown__item-text">
                        Снижение кадастровой стоимости
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">04</div>
                      <div class="dropdown__item-text">
                        Узаконение реконструкций и&nbsp;перепланировок
                      </div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">05</div>
                      <div class="dropdown__item-text">Регистрация ИП, ООО</div>
                    </div>
                    <div class="dropdown__item">
                      <div class="dropdown__item-number">06</div>
                      <div class="dropdown__item-text">
                        Консультации по налогообложению
                      </div>
                    </div>
                  </div>
                  <div class="dropdown__image-container">
                    <img
                      class="dropdown__image"
                      :src="require('@/assets/img/dropdown3.jpg')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer @generatePdf="generatePdf" />
      <CoolLightBox
        class="colllightbox"
        :items="[
          {
            src: picture,
            autoplay: false,
            muted: false,
            disablepictureinpicture: true,
          },
        ]"
        :index="index"
        @close="onClose()"
      >
      </CoolLightBox>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :manual-pagination="true"
        pdf-content-width="1440px"
        :ref="`html2Pdf_1`"
        :html-to-pdf-options="htmlToPdfOptions"
      >
        <section slot="pdf-content">
          <div>
            <PrintTemplate />
          </div>
        </section>
      </vue-html2pdf>
    </div>
    <div v-else-if="Item === false" class="preloader">Объект не найден</div>
    <div v-else class="preloader">Идёт загрузка...</div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { mapActions, mapGetters } from "vuex";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueHtml2pdf from "vue-html2pdf";
import PrintTemplate from "@/components/PrintTemplate.vue";
export default {
  name: "Home",
  data: () => ({
    picture: "",
    index: null,
    mainSettings: {
      arrows: false,
      dots: false,
      adaptiveHeight: true,
      draggable: false,
    },
    thumbSettings: {
      slidesToShow: 6,
      arrows: false,
      dots: false,
      draggable: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 5,
          },
        },
      ],
    },
    mapMainSettings: {
      apiKey: "2fc16756-64e8-4a86-b899-d377c0b5ca05",
      lang: "ru_RU",
      coordorder: "latlong",
      enterprise: false,
      version: "2.1",
    },
    mapSettings: {
      zoom: 15,
      controls: ["geolocationControl", "zoomControl"],
    },
    icon: {
      layout: "default#image", // 'default#imageWithContent' для использования с контентом
      imageHref:
        "data:image/svg+xml,%3Csvg width='46' height='56' viewBox='0 0 46 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22.9744' cy='22.9744' r='22.9744' fill='%23EE3686'/%3E%3Cpath d='M22.9744 56L42.8707 29.0769H3.07798L22.9744 56Z' fill='%23EE3686'/%3E%3Cpath d='M16.0579 17.6335H8.16342C8.08045 17.8956 8.08297 17.8854 8 18.1475L16.9253 24.6666L13.5261 35.2952C13.7474 35.4555 13.7373 35.4504 13.9586 35.6107L20.2767 30.9287L16.0554 17.6335H16.0579Z' fill='white'/%3E%3Cpath d='M28.744 24.6663L37.7196 18.1472C37.6366 17.8851 37.6391 17.8953 37.5561 17.6332H29.6642L25.4429 30.9284L31.761 35.6104C31.9822 35.4475 31.9722 35.4552 32.1934 35.2923L28.744 24.6663Z' fill='white'/%3E%3Cpath d='M22.5997 7L19.2207 17.6337L22.5997 28.2673H23.1202L26.4992 17.6337L23.1202 7H22.5997Z' fill='white'/%3E%3C/svg%3E%0A", // адрес изображения или data:image/svg+xml;base64
      imageSize: [43, 55], // размер иконки в px
      imageOffset: [0, 0], // смещение иконки в px,
      /* Следующие поля актуальны для layout: 'default#imageWithContent' */
      content: "", // содержимое контента
      contentOffset: [0, 0], // смещение контента в px,
      contentLayout: "", // строковый HTML шаблон для контента
    },
  }),
  components: {
    VueSlickCarousel,
    Header,
    yandexMap,
    ymapMarker,
    Footer,
    CoolLightBox,
    VueHtml2pdf,
    PrintTemplate,
  },
  computed: {
    ...mapGetters(["Item"]),
    currentLead() {
      return this.Item.leads[0];
    },
    images() {
      if(!this.Item.config.images) return []
      let imgFields = this.Item.config.images.map((el) => el.id)
      let imgArr = []
      for (let imgProp of imgFields) {
        imgArr = imgArr.concat(Object.keys(JSON.parse(this.getCustomPropById(imgProp))))
      } 
      return imgArr;
    },
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: this.getCustomProp("presentationNameFieldId") + ".pdf",
        image: {
          type: "jpeg",
          quality: 1,
        },
        enableLinks: false,
        html2canvas: {
          scale: 1.2,
          width: 1440,
          useCORS: true,
        },
        jsPDF: {
          unit: "px",
          format: "a4",
          orientation: "portrait",
          hotfixes: ["px_scaling"],
        },
      };
    },
    mapMarker() {
      return {
        markerId: 1,
        hideIconOnBalloonOpen: false,
        coords: [
          this.getCustomProp("latitude"),
          this.getCustomProp("longitude"),
        ],
        options: {
          hideIconOnBalloonOpen: false,
          syncOverlayInit: true,
          balloonOffset: [0, -30],
        },
      };
    },
  },
  methods: {
    ...mapActions(["fetchItem"]),
    getCustomProp(propName) {
      let prop = this.currentLead.custom_fields_values.find(
        (field) => field.field_id === this.Item.config[propName]
      );
      if (prop) {
        return prop.values[0].value;
      }
      return false;
    },
    getCustomPropById(id) {
      let prop = this.currentLead.custom_fields_values.find(
        (field) => field.field_id === id
      );
      if (prop) {
        return prop.values[0].value;
      }
      return false;
    },
    onClose() {
      this.index = null;
      this.picture = "";
    },
    openFancybox(src) {
      this.picture = src;
      this.index = 0;
    },
    beforeChange(oldSlideIndex, newSlideIndex) {
      this.$refs.thumbSlider.goTo(newSlideIndex);
    },
    generatePdf() {
      this.$refs[`html2Pdf_1`].generatePdf();
    },
    getYoutubeId() {
      if (this.getCustomProp("videoId")) {
        return this.getCustomProp("videoId").split("v=")[1];
      }
      return false;
    },
  },
  created() {
    this.fetchItem({
      personalId: this.$route.params.id,
      leadId: this.$route.query.ids,
    });
  },
};
</script>

<style lang="scss" scoped>
.slider {
  padding: 40px 0 60px;
  background-color: #f5f5f5;

  &__title {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__img {
    border-radius: 20px;
    // height: 640px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      // height: 450px;
    }
  }

  &__container {
    position: relative;
  }

  &__arrows {
    width: 100%;
    height: 50px;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: #000;
    transition: 0.3s;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #ee3686;
    }

    &_left {
      left: -25px;
    }

    &_right {
      right: -25px;
    }
  }

  &__thumb {
    margin: 20px -10px 0;
    @media screen and (max-width: 767px) {
      margin: 5px 0 0 -2px;
    }
    &-img {
      border-radius: 10px;
      width: 100%;
      object-fit: cover;
      height: 100%;
      object-position: center;

      &-container {
        padding: 0 10px;
        position: relative;
        height: 112px;
        cursor: pointer;
        @media screen and (max-width: 767px) {
          height: 55px;
          padding: 0 4px;
        }
      }
    }
  }
}

.main {
  &-title {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
    line-height: 37px;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
  }

  &-info {
    padding: 20px 0 40px;
    background-color: #fff;
    @media screen and (max-width: 767px) {
      border-radius: 20px;
      padding: 20px 24px 40px;
    }

    &__address {
      & span {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;

        @media screen and (max-width: 767px) {
          display: block;
          font-size: 16px;
          line-height: 18px;
        }
      }
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__props {
      margin-bottom: 40px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 70px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        row-gap: 15px;
      }
    }

    &__prop {
      &-name {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
      }

      &.mobile-hide {
        display: block;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &.desktop-hide {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
        }
      }

      &-value {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    &__description {
      font-size: 24px;
      font-weight: 500;
      line-height: 27px;
      text-align: justify;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
        text-align: left;
      }
    }
  }
}

.map-section {
  padding: 40px 0 60px;
  background-color: #f5f5f5;
  &__row {
    margin-bottom: 1px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 32px), 1fr));

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      row-gap: 40px;
    }
  }

  &__map-container {
    width: 100%;
    overflow: hidden;
  }

  &__video {
    &-frame {
      width: 100%;
      height: 400px;
      border-radius: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      padding: 0;
    }
  }
}

.agent-section {
  padding: 40px 0;
  background-color: #fff;
  &__agent {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      align-items: stretch;
    }
  }
}

.agent {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;

    &-container {
      width: 140px;
      height: 140px;

      .agent {
        &__phone {
          &-icons {
            display: none;
          }
        }
      }

      @media screen and (max-width: 767px) {
        width: 127px;
        flex-shrink: 0;
        height: auto;
        position: relative;

        .agent {
          &__phone {
            &-icons {
              position: absolute;
              bottom: 0;
              transform: translateY(50%) translateX(-50%);
              left: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 8px;

              & svg {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &__info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-left: 20px;
      align-items: stretch;
    }
  }

  &__name {
    font-size: 32px;
    font-weight: 500;
    line-height: 37px;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }

    &-container {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  &__position {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__contacts-container {
    margin-left: 80px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &__email {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      text-decoration: underline;
    }

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    & span {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__phone {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      text-decoration: underline;
    }

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    & span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }
    }

    &-icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 767px) {
        display: none;
      }

      & svg {
        flex-shrink: 0;
        margin-left: 8px;
      }
    }
  }
}

.bottom-banner {
  height: 194px;
  padding: 40px;
  background-image: url("~@/assets/img/bottom.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;

  @media screen and (max-width: 767px) {
    height: 231px;
    background-image: url("~@/assets/img/bottom_mob.jpg");
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 57px;
    color: #fff;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }

    & span {
      display: block;

      @media screen and (max-width: 767px) {
        font-size: 48px;
        line-height: 58px;
        text-align: center;
      }
    }
  }

  &__button {
    margin-left: auto;
    background-color: #fff;
    color: #000000;
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 20px;
    }

    &:hover {
      background-color: #ee3686;
      color: #fff;
    }
  }
}

.dropdowns {
  padding: 40px 0 60px;
  background-color: #f5f5f5;
}

.dropdown {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &__body {
    max-height: 0;
    transition: 0.3s;
    overflow: hidden;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    margin-right: auto;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__arrow {
    margin-left: 30px;
    transition: 0.3s;
    @media screen and (max-width: 767px) {
      margin-left: 20px;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 0.65fr 0.35fr;
    gap: 20px;

    &_reverse {
      grid-template-columns: 0.35fr 0.65fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    &-container {
      width: 100%;
      height: 100%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__item {
    padding: 40px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      padding: 20px;
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-number {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      color: #ee3686;
      margin-right: 20px;
    }

    &-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 27px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  & input {
    display: none;
  }

  & input:checked {
    & ~ .dropdown {
      &__body {
        max-height: 1000px;
        margin-top: 40px;
        @media screen and (max-width: 767px) {
          margin-top: 20px;
        }
      }

      &__head {
        .dropdown {
          &__arrow {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  width: fit-content;
  padding: 20px;
  transition: 0.3s;
  white-space: nowrap;
  border-radius: 999px;
  outline: none;
  text-decoration: none;
  border: 0;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>

<style lang="scss">
.slider__thumb {
  & .slick-slide {
    & .slider__thumb-img-container {
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 10px;
        width: calc(100% - 20px);
        border-radius: 10px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: block;
        content: "";
        transition: 0.3s;
        @media screen and (max-width: 767px) {
          width: calc(100% - 8px);
          left: 4px;
        }
      }
    }
  }
  & .slick-current {
    & img {
      border: 2px solid #ee3686;
      @media screen and (max-width: 767px) {
        border-width: 1px;
      }
    }

    & .slider__thumb-img-container {
      position: relative;
      &::before {
        opacity: 0;
      }
    }
  }
}

.main-info__description {
  p {
    margin-bottom: 8px;
  }

  ul {
    margin: 10px 0;
    list-style: initial;
    padding-inline-start: 40px;
  }

  li {
    margin-bottom: 8px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  b, strong {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }
}

.map-section {
  &__map {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      height: 480px;
    }
  }
}
</style>
